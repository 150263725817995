<template>
  <header>
    <img class = "logo" src = '@/assets/logo.png' alt = 'fehler'>

    <div class="icon-text-wrapper">
      <img class="fwlogo" src="img/icons/android-chrome-maskable-192x192.png" alt="Icon">
      <h3>Built with vue.js</h3>
    </div>

    <button @click="logout" class="logout-button">Logout</button>
  </header>
</template>



<script>
export default {
  name: 'Header',
  methods: {
    logout() {
      // Lösche das Cookie
      document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';

      // Lösche den localStorage
      localStorage.removeItem('id');

      window.location.reload();
    }
  }
}

</script>



<style scoped>
header {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  align-items: flex-end; /* Am unteren Rand des Headers ausrichten */
  justify-content: space-between;
  padding: 8px 16px; /* Etwas weniger Padding für einen kleineren Look */
}

.logo {
  max-width: 15%; /* Logo kleiner machen */
  height: auto;
  margin-left: 16px; /* Kleinere Abstände */
}

.icon-text-wrapper {
  display: flex;
  align-items: flex-end; /* Icon und Text am unteren Rand ausrichten */
  gap: 0; /* Entfernt den Abstand zwischen Icon und Text */
}

.fwlogo {
  max-width: 24px; /* Icon kleiner machen */
  height: 24px; /* Icon auf feste Höhe setzen */
  margin: 0; /* Kein Abstand */
}

h3 {
  font-size: 14px; /* Kleinere Schriftgröße */
  color: #6c757d; /* Graue Farbe für den Text */
  margin: 0; /* Kein Margin */
  line-height: 24px; /* Zeilenhöhe passend zum Icon */
}

.logout-button {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 6px 12px;
  font-size: 14px; /* Kleinere Schriftgröße für den Button */
  border-radius: 8px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: rgba(0, 166, 255, 0.64);
}

</style>