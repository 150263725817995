<script setup>
import { ref } from 'vue';

// Emits-Definition
const emit = defineEmits(['addTask']);

// Reaktive Referenzen
const dialogRef = ref(null);
const newTask = ref({
  title: '',
  date: '',
  time: ''
});

// Funktionen
const showDialog = () => dialogRef.value?.showModal();
const closeDialog = () => dialogRef.value?.close();


const addTask = async () => {
  if (newTask.value.title && newTask.value.date && newTask.value.time) {
    const response = await fetch(`${process.env.VUE_APP_API}/setTask`, {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({
        uid: localStorage.getItem('id'),
        title: newTask.value.title,
        date: newTask.value.date,
        time: newTask.value.time,
        timestamp: Math.floor(new Date(newTask.value.date + " " + newTask.value.time).getTime() / 1000)
      })
    });
    closeDialog();
    newTask.value = { title: '', date: '', time: '' }; // Formular leeren
    emit('addTask');
  }
};

</script>

<template>
  <button class="openButton" @click="showDialog">+</button>
  <dialog class="popup" ref="dialogRef">
    <button class="closeButton" @click="closeDialog">x</button>
    <h2 class="header">New Task</h2>
    <form @submit.prevent="addTask">
      <div class="input">
        <label for="title">Task:</label>
        <input id="title" type="text" v-model="newTask.title">
      </div>
      <div class="input">
        <label for="date">Date:</label>
        <input id="date" type="date" v-model="newTask.date">
      </div>
      <div class="input">
        <label for="time">Time:</label>
        <input id="time" type="time" v-model="newTask.time">
      </div>
      <button type="submit" class="addButton">Add</button>
    </form>
  </dialog>
</template>

<style scoped>
.openButton {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed; /* Ändere position auf fixed */
  bottom: 20px; /* Abstand vom unteren Rand */
  right: 20px; /* Abstand vom rechten Rand */
  z-index: 1000; /* Stellt sicher, dass der Button über anderen Inhalten liegt */
}

.openButton:hover {
  background-color: rgba(0, 166, 255, 0.64);
}

.addButton {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 6px;
  font-size: 25px;
  margin: 6px;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.addButton:hover {
  background-color: rgba(0, 166, 255, 0.64);
}

.popup {
  background-color: white;
  padding: 20px;
  border: none;
  border-radius: 8px;
  width: 80%;
  max-width: 100%;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.closeButton {
  float: right;
  margin: 6px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.input {
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 6px;
}

input {
  width: calc(100% - 60px);
  border: none;
  border-radius: 8px;
}

label {
  width: 60px;
  float: left;
}

form {
  text-align: center;
}

h2 {
  text-align: center;
  color: rgb(0, 166, 255);
}
</style>
