<template>
  <Task></Task>
  <Task></Task>
  <Task></Task>
</template>

<script>
// @ is an alias to /src

import Task from "@/components/Task.vue";

export default {
  name: 'HomeView',
  components: {
    Task,
  }
}
</script>
