<script setup>
import { defineProps, defineEmits } from 'vue';

// Props-Definition
const props = defineProps({
  task: {
    type: Object,
    required: true
  }
});

// Emits-Definition
const emit = defineEmits(['checkTask']);

const checkTask = async (tid) => {
  const response = await fetch(`${process.env.VUE_APP_API}/checkTask`, {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify({
      uid: localStorage.getItem('id'),
      tid: tid
    })
  });
  emit('checkTask');

};
</script>

<template>
  <div class="task">
    <div class="input">
      <input class="checkbox" type="button" @click="checkTask(props.task['tid'])">
    </div>
    <div class="description" v-html="props.task['title']"></div>
    <div class="date">{{ props.task['date'] }}</div>
    <div class="time">{{ props.task['time'] }}</div>
  </div>
</template>

<style scoped>
.task {
  margin: 8px;
  padding: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.checkbox {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.description {
  margin-left: 16px;
  flex: 1;
}

.date, .time {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
}

.date {
  margin-right: 8px;
}
</style>
