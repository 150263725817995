<script setup>
import { ref } from 'vue';

const input = ref({
  uname: '',
  email: '',
  password: '',
  confirmPassword: '' // Neues Feld für die Passwort-Bestätigung
});


const ffsignup = () => {
  if (input.value.password !== input.value.confirmPassword) {
    alert("Passwords do not match!");
    return;
  }


  console.log('Sign-up erfolgreich', input.value);
}

const signup = async () => {
  if (input.value.password !== input.value.confirmPassword) {
    alert("Passwords do not match!");
    return;
  }try {
    const response = await fetch(`${process.env.VUE_APP_API}/setUser`, {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({
        email: input.value.email,
        pwd: input.value.password,
        uname: input.value.uname
      })
    });

    const response1 = await fetch(`${process.env.VUE_APP_API}/getUser/`+input.value.email, { method: 'GET' });
    const user = await response1.json();

    document.cookie = `authToken=${user['id']}; path=/; max-age=86400`;
    localStorage.setItem('id', user['id'])// 1 Tag gültig

    input.value = { email: '', password: '', uname: '' };
    window.location.reload();
  }catch(err) {
    alert("Email already exists!");
    console.log(err);
  }
};

</script>

<template>
  <h2 class="header">Please sign up</h2>
  <form @submit.prevent="signup">
    <div class="input">
      <label for="uname">Name: </label>
      <input id="uname" type="text" v-model="input.uname" required>
    </div>
    <div class="input">
      <label for="email">Email: </label>
      <input id="email" type="email" v-model="input.email" required>
    </div>
    <div class="input">
      <label for="password">Password: </label>
      <input id="password" type="password" v-model="input.password" required>
    </div>
    <div class="input">
      <label for="confirmPassword">Confirm Password: </label>
      <input id="confirmPassword" type="password" v-model="input.confirmPassword" required>
    </div>
    <button type="submit" class="addButton">Sign Up</button>
  </form>
</template>

<style scoped>

.addButton {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 6px;
  font-size: 25px;
  margin: 6px;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.addButton:hover {
  background-color: rgba(0, 166, 255, 0.64);
}

.input {
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 6px;
}

input {
  width: calc(100% - 60px);
  border: none;
  border-radius: 8px;
}

label {
  width: 200px;
  float: left;
  text-align: left;
  margin-left: 30px;
}

form {
  text-align: center;
}

h2 {
  text-align: center;
  color: rgb(0, 166, 255);
}
</style>
