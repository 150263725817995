<script setup>
import { ref } from 'vue';

const input = ref({
  email: '',
  password: ''
});

// Funktion zur Authentifizierung
const signin = async () => {
  if (input.value.email && input.value.password) {
    const response = await fetch(`${process.env.VUE_APP_API}/authenticate`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        email: input.value.email,
        pwd: input.value.password
      })
    });

    if (response.ok) {
      const userId = await response.text();

      // Cookie setzen
      document.cookie = `authToken=${userId}; path=/; max-age=86400`;
      localStorage.setItem('id', userId)// 1 Tag gültig
    } else {
      console.log('Authentication failed');
    }
    input.value = { email: '', password: '' };
    window.location.reload();
  }
};

</script>

<template>
    <h2 class="header">Please sign in</h2>
    <form @submit.prevent="signin">
      <div class="input">
        <label for="email">Email: </label>
        <input id="email" type="email" v-model="input.email" required>
      </div>
      <div class="input">
        <label for="password">Password: </label>
        <input id="password" type="password" v-model="input.password" required>
      </div>
      <button type="submit" class="addButton">sign in</button>
    </form>
</template>

<style scoped>

.addButton {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 6px;
  font-size: 25px;
  margin: 6px;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.addButton:hover {
  background-color: rgba(0, 166, 255, 0.64);
}



.input {
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 6px;
}

input {
  width: calc(100% - 60px);
  border: none;
  border-radius: 8px;
}

label {
  width: 200px;
  float: left;
  text-align: left;
  margin-left: 30px;
}

form {
  text-align: center;
}

h2 {
  text-align: center;
  color: rgb(0, 166, 255);
}
</style>
