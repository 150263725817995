<template>
  <dialog class="popup" ref="dialogRef">
    <div v-if="isSignin">
      <Signin />
    </div>
    <div v-else>
      <Signup />
    </div>

    <div class="content">
      <!-- Der bedingte Text über dem Link -->
      <p class="info-text">{{ isSignin ? 'Need a new account?' : 'Already have an account?' }}</p>
      <!-- Der Link zum Umschalten zwischen Signin und Signup -->
      <button class="link" @click="toggleForm">{{ isSignin ? 'sign up' : 'sign in' }}</button><br>
    </div>
  </dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Signup from "@/components/Signup.vue";
import Signin from "@/components/Signin.vue";

// Referenz für das Dialogfeld und den Zustand für die Login-Informationen
const dialogRef = ref(null);
const authenticated = ref(false);

// Zustand, um zwischen Signin und Signup zu wechseln
const isSignin = ref(true);

// Funktion zum Wechseln zwischen Signin und Signup
const toggleForm = () => {
  isSignin.value = !isSignin.value;
};

// Funktion zum Überprüfen des Cookies
const checkLoginCookie = () => {
  const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('authToken='));
  return cookieValue ? cookieValue.split('=')[1] : null;
};

// Funktion zum Anzeigen des Dialogfelds
const showDialog = () => {
  if (!authenticated.value) {
    dialogRef.value?.showModal();
  }
};

// Funktion zum Schließen des Dialogfelds
const closeDialog = () => dialogRef.value?.close();

// Funktion, die beim Laden der Komponente ausgeführt wird
onMounted(() => {
  const authToken = checkLoginCookie();
  if (authToken) {
    authenticated.value = true; // Wenn der Cookie existiert, ist der Nutzer eingeloggt
  } else {
    showDialog(); // Wenn kein Cookie existiert, Dialogfeld anzeigen
  }
});
</script>

<style scoped>
.popup {
  background-color: white;
  padding: 20px;
  border: none;
  border-radius: 8px;
  width: 80%;
  max-width: 100%;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.link {
  background-color: rgb(0, 166, 255);
  border: none;
  font-size: 16px;
  color: white;
  padding: 4px;
  margin: 6px;
  border-radius: 8px;
  cursor: pointer;
}

.link:hover {
  background-color: rgba(0, 166, 255, 0.64);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-text {
  margin-bottom: 4px;
}
</style>
