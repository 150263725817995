<template>
  <div>
    <Header />
    <AuthenticateView/>
    <div class="notifications" v-if="!permissionGranted">
      <p>
        Enable Notifications to not miss your tasks!
      </p>
      <div class="button-container">
        <button class="reqButton" v-on:click="requestPermissions">Enable Notifications</button>
      </div>
    </div>
    <Task
        v-for="(task) in tasks"
        :key="task.id"
        :task="task"
        @checkTask="refreshTasks"
    />
    <AddTask @addTask="refreshTasks" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import Header from '@/components/Header.vue';
import Task from './components/Task.vue';
import AddTask from './components/AddTask.vue';
import AuthenticateView from "@/views/AuthenticateView.vue";

// Aufgabenliste
const tasks = ref([]);

// Status der Benachrichtigungsberechtigung
const old = ref(false);
const permissionGranted = computed(() => Notification.permission === "granted");


// Aufgaben abrufen
const getTasks = async (id) => {
  const response = await fetch(`${process.env.VUE_APP_API}/getTasks/${id}`, { method: 'GET' });
  const tasksData = await response.json();
  return tasksData;
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

const refreshTasks = async () => {
  tasks.value = await getTasks(localStorage.getItem('id'));
};

const requestPermissions = async () => {
  if (!("Notification" in window)) {
    alert("Dieser Browser unterstützt keine Desktop-Benachrichtigungen");
    console.log("Notifications not supported by browser");
    return;
  }
  await Notification.requestPermission();
  window.location.reload();
  return;
}

const newsubHandler = async () => {
  console.log("_______________________________________________________");
  console.log("Welcome to the New SubHandler!");
  let situation = 0;
  let permission = false;
  let localSub = false;
  let localAndDbSub = false;

  console.log("--Step 1: Check Notification Permissions:");
  if (Notification.permission !== "granted") {
    console.log("Permissions not granted");
    localStorage.removeItem('sub');
  }else{
    permission = true;
    console.log("Permissions Granted");
  }

  console.log("--Step 2: Check for Local Sub-Item:");
  if(permission && localStorage.getItem('sub')){
    localSub = true;
    console.log("Found local Sub-Item!")
    console.log(localStorage.getItem('sub'));
  }else{
    localSub = false;
    console.log("No local Sub-Item found.");
  }

  if(permission && localSub){
    console.log("--Step 3: Compare local Sub-Item with Database Sub-Items:");
    //Get sub from Database
    const response = await fetch(`${process.env.VUE_APP_API}/getSubs`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: localStorage.getItem('id')
      })
    });

    const dbSubs = await response.json();
    const storedSub = JSON.parse(localStorage.getItem('sub'));

    console.log("Fetched from Database: ");
    console.log(dbSubs);
    //compare all DB results with local stored Sub
    for (const sub of dbSubs) {
      sub.sub = JSON.parse(sub.sub);
      console.log("Comparing from Database: ")
      console.log(sub.sub)
      console.log("Type of sub.sub:", typeof sub.sub);
      console.log("With from Local Storage: ")
      console.log(storedSub);
      console.log("Type of storedSub:", typeof storedSub);

      const isEndpointEqual = sub.sub.endpoint === storedSub.endpoint;
      const isP256dhEqual = sub.sub.keys.p256dh === storedSub.keys.p256dh;
      const isAuthEqual = sub.sub.keys.auth === storedSub.keys.auth;

      console.log(`Endpoint comparison: ${isEndpointEqual}`);
      console.log(`p256dh Key comparison: ${isP256dhEqual}`);
      console.log(`auth Key comparison: ${isAuthEqual}`);

      if (isEndpointEqual && isP256dhEqual && isAuthEqual) {
        console.log("Subscription match found!")
        localAndDbSub = true;
      }
    }
  }

  if(permission && localSub && localAndDbSub){
    situation = 1
  }else if(permission && localSub && !localAndDbSub ){
    situation = 2;
  }else if(permission && !localSub){
    situation =3;
  }


  switch(situation){
    case 1:
      //Everything is fine, nothing to do
      console.log("Case 1");
      break;
    case 2:
      //Local Sub needs to be uploaded to Database
      await saveToDatabase(localStorage.getItem('sub'));
      console.log("Case 2");
      await newsubHandler();
      break;
    case 3:
      //New Sub has to be generated and must be stored in Local Storage and Database
      await generateAndSaveNewSub()
      console.log("Case 3");
      await newsubHandler();
      break;
    default:
      console.log("Default");
  }

}
const saveToDatabase = async (sub) => {
  const response = await fetch(`${process.env.VUE_APP_API}/setSub`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      id: localStorage.getItem('id'), // Temporäre feste ID direkt hier verwenden
      sub: sub // sicherstellen, dass sub als Objekt gespeichert wird
    })
  });
  console.log("Saved Subscription to Database", response);
}


const generateAndSaveNewSub = async () => {
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    console.error("Service Worker not registered");
    return;
  }
  let subscription = await registration.pushManager.getSubscription();
  if (!subscription) {
    // Erstelle eine neue Subscription, falls keine existiert
    const applicationServerKey = urlBase64ToUint8Array("BDE7iRm5f_lcT88EW3ert7cJpHgRkuKlaAbeCosZhLRzfBO1aSz8oXOqqQwj5BTuDT1c51DiNvVySnQWmlGCq64");
    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey
    });
    localStorage.setItem("sub", JSON.stringify(subscription));
  } else {
    localStorage.setItem("sub", JSON.stringify(subscription));

  }
}


// Hole Aufgaben nach der Montage der Komponente
onMounted(async () => {
  if(localStorage.getItem('id')){
    tasks.value = await getTasks(localStorage.getItem('id')); // Setze die abgerufenen Aufgaben
    await newsubHandler();
  }

});
</script>

<style>
body {
  margin: 0;
}

.notifications {
  margin: 8px;
  padding: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stellt sicher, dass Kinder vertikal angeordnet werden */
  align-items: center; /* Zentriert den Inhalt horizontal */
}

.button-container {
  margin-top: 12px; /* Abstand zwischen Text und Button */
}

.reqButton {
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.reqButton:hover {
  background-color: rgba(0, 166, 255, 0.64);
}
</style>
